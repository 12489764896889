define(['app'], function(app) {
  const videoGallery = () => {
    const component = {};

    let _config = {
      selectors: {
        videoFrame: '.videoGalleryFrame',
        transcriptAccordion: '[data-accordion]',
        transcriptAccordionButton: '.accordionWidget_sectionTitle'
      },
      attributes: {
        ariaExpanded: 'aria-expanded'
      }
    };

    const _init = (element) => {
      component.element = element;
      component.videoFrameContainer = component.element.querySelector(component.config.selectors.videoFrame);
      component.videoFrameContent = component.videoFrameContainer.contentWindow;
      component.transcriptAccordion = component.element.querySelector(component.config.selectors.transcriptAccordion);
      component.accordionWidgetButton = (component.transcriptAccordion)
        && component.transcriptAccordion.querySelector(component.config.selectors.transcriptAccordionButton);
      app.subscribe('modal/modalClosed', component.pauseAndCloseVideo);
    };



    const _pauseAndCloseVideo = () => {

      // check if it is a html5 video
      if (component.videoFrameContainer && component.videoFrameContainer.load) {
        component.pauseAndCloseHTML5Video();
      }
      
      // if an iframe use the youtube api to stop the video 
      if (component.videoFrameContent && component.videoFrameContent.postMessage) {
        component.pauseAndCloseIframeVideo();
      }

      component.accordionWidgetButtonCheck();

    };

    const _accordionWidgetButtonCheck = () => {
      if((component.accordionWidgetButton) !== null) {
        const isAccordionOpen = component.accordionWidgetButton.getAttribute(component.config.attributes.ariaExpanded);
        (isAccordionOpen === 'true') && component.accordionWidgetButton.click();
      }
    }

    const _pauseAndCloseHTML5Video= () => {
      component.videoFrameContainer.pause();
      component.videoFrameContainer.load();
    };

    const _pauseAndCloseIframeVideo = () => {
      component.videoFrameContent.postMessage(
        '{"event":"command","func":"' + 'stopVideo' + '","args":""}',
        '*'
      )
    };

    component.config = _config;
    component.pauseAndCloseVideo = _pauseAndCloseVideo;
    component.pauseAndCloseHTML5Video = _pauseAndCloseHTML5Video;
    component.pauseAndCloseIframeVideo = _pauseAndCloseIframeVideo;
    component.accordionWidgetButtonCheck = _accordionWidgetButtonCheck;
    component.init = _init;

    return component;
  };

  return videoGallery;
});
